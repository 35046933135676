// src/origamiData.js
// const origamiData = [
//     {
//       id: 1,
//       name: "Chick",
//       description: "The origami crane is a classic design and symbolizes peace.",
//       imageUrl: "./images/A-Chick.jpg",
//     },
//     // {
//     //   id: 2,
//     //   name: "Acrocinus Logimanus",
//     //   description: "The origami crane is a classic design and symbolizes peace.",
//     //   imageUrl: "./images/Acrocinus-logimanus.jpg",
//     // },
//     {
//       id: 3,
//       name: "African Elephant",
//       description: "The origami crane is a classic design and symbolizes peace.",
//       imageUrl: "./images/African-Elephant.jpg",
//     },
//     {
//       id: 4,
//       name: "African Gorilla",
//       description: "The origami crane is a classic design and symbolizes peace.",
//       imageUrl: "./images/African-Gorilla.jpg",
//     },    
//   ];
  
const origamiData = [
  { 
    id: 1,
    "title": "Goldfish",
    "description": "This model was folded from a sheet of kami, I think the Aitoh ones with the rainbow design.",
    "designer": "Designed by ???",
    "paper": "15 cm Kami",
    "source": "Origami by Rick Beech",
    "img-local": "./images/DSC_0003.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/eed39c84-e26d-4367-acb4-7ccad6614200/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 2,
    "title": "Masu Box",
    "description": "This traditional design with the ever-so satisfying locking technique is so cool!",
    "designer": "Traditional model",
    "paper": "2 sheets of 20 cm watercolor paper",
    "source": "Origami by Rick Beech, you can get this from many other books too.",
    "img-local": "./images/DSC_0009.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/05108a70-a9f9-454c-4fd5-48db80fda400/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Objects"
  },
  {
    "id": 3,
    "title": "Common Goldfish",
    "description": "This model was very fun to fold because deluxe washi is a very cool paper.",
    "designer": "Shuki Kato",
    "paper": "24 cm deluxe washi",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_0010.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2ebfbf7d-7d79-4246-207d-8f525b4ee700/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 4,
    "title": "Cat",
    "description": "I made this tissue paper at home, and it worked out amazing for this model.",
    "designer": "Katsuta Kyohei",
    "paper": "40 cm double tissue",
    "source": "Works of Katsuta Kyohei by Katsuta Kyohei",
    "img-local": "./images/DSC_0011.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a3d7bd4d-eae1-4c09-9447-d578e38e5d00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 5,
    "title": "Dancing Crane",
    "description": "This is also made from home made tissue and I especially love the layout of the model.",
    "designer": "Robert Lang",
    "paper": "40 cm double tissue",
    "source": "Origami Design Secrets (1st and 2nd edition) by Robert Lang",
    "img-local": "./images/DSC_0014.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/f3e27aa5-ce6a-48dd-c840-58f10616b100/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Birds"
  },
  {
    "id": 6,
    "title": "Giraffe",
    "description": "This model was wet folded because kraft paper is just too crisp to be shaped into soft forms.",
    "designer": "Shuki Kato",
    "paper": "50 cm Kraft",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_0021.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/31620e3e-fbba-45a0-3070-d0f9482a4e00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 7,
    "title": "Eagle",
    "description": "The paper used for this is tissue foil which makes it amazingly metallic. Also, this model is one of my favorite because the details in the wings are ingenious.",
    "designer": "Nguyen Hung Cuong",
    "paper": "60 cm tissue-foil",
    "source": "License to Fold by Nicolas Terry (and many other contributors)",
    "img-local": "./images/DSC_0023.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Birds"
  },
  {
    "id": 8,
    "title": "Brachiosaurus",
    "description": "I folded this model back in the time when I didn't have spray adhesive. So to make my tissue foil I used some very thick aluminium foil and used a mixture of water/glue to make the paper. This was sort of MC'ing it.",
    "designer": "Shuki Kato",
    "paper": "40 cm thick tissue foil",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_0025.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/9e733cd0-708a-4553-a813-adb1284ae600/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 9,
    "title": "Plate/Bowl Pureland Tessellation",
    "description": "This model looks complex but the layout is simple. It is just pleats on pleats on pleats.",
    "designer": "Goran Konjevod",
    "paper": "35 cm TANT",
    "source": "Taught at WOD 2020, recordings available at OrigamiUSA",
    "img-local": "./images/DSC_0028.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6095fba1-e1b2-4f3b-5943-878bc7053200/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Objects"
  },
  {
    "id": 10,
    "title": "3D Duck",
    "description": "Of course, this model was wet folded, and it was so fun!!!",
    "designer": "Nicolas Terry",
    "paper": "20 cm watercolor paper",
    "source": "License to Fold by Nicolas Terry (and many other contributors)",
    "img-local": "./images/DSC_0029.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/2722c3fe-fcfc-45b9-c072-23b39ca20a00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 11,
    "title": "Succulent",
    "description": "The vase is a way of making 3D like I never knew. It starts of with a triangle grid like you use for tessellations and then pleat up the sides. The plant itself is a lot of pentagonal bird bases.",
    "designer": "Yara Yagi",
    "paper": "3 pentagonal sheets of TANT, various sizes, 1 hexagonal sheet of thick paper, 20 cm",
    "source": "Taught at WOD 2020, recordings available at OrigamiUSA",
    "img-local": "./images/DSC_0033.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/48068dfb-53fb-4a73-341b-be578904e600/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "People"
  },
  {
    "id": 12,
    "title": "Grey Wolf",
    "description": "Grey Wolf",
    "designer": "Shuki Kato",
    "paper": "1 Sheet of 35cm Octa",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_0004.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e3f14b0a-e37b-499c-a684-c5fcb8f50e00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 13,
    "title": "Complex Dragonfly",
    "description": "Complex Dragonfly",
    "designer": "Shuki Kato",
    "paper": "1 Sheet of 50cm kraft",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_0005.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/06cd0739-e246-4c4a-0e87-bef600d2ad00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Birds"
  },
  {
    "id": 14,
    "title": "Cake",
    "description": "Cake",
    "designer": "Paper Kawaii",
    "paper": "1 white rectangle, 2 sheets of yellow kami and 2 tiny sheets of red kami",
    "source": "Taught at WOD 2020, recordings available at OrigamiUSA",
    "img-local": "./images/DSC_0006.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/fd355e93-79c2-4039-2e51-c3b06789cc00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Objects"
  },
  {
    "id": 15,
    "title": "T-Rex",
    "description": "T-Rex",
    "designer": "Unknown",
    "paper": "1 Sheet of 15cm golden river/leather paper",
    "source": "Instruction came with the paper pack",
    "img-local": "./images/DSC_0007.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6fc71533-0132-43f3-8330-f6713e2e3000/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 16,
    "title": "Simple Dragon",
    "description": "Simple Dragon",
    "designer": "Shuki Kato",
    "paper": "1 Sheet of 35cm kami",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_0008.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/47d1442a-06eb-4031-ae74-ee9ea37c7a00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 17,
    "title": "Bactrian Camel - Fold1",
    "description": "Bactrian Camel",
    "designer": "Shuki Kato",
    "paper": "1 Sheet of 35cm kami",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_00091.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/d090d77d-a333-4298-6731-56ab953b6a00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 18,
    "title": "Elephant",
    "description": "Elephant",
    "designer": "Enomoto Nobuyoshi",
    "paper": "1 Sheet of 15cm momi irizome",
    "source": "Origami by Rick Beech",
    "img-local": "./images/DSC_00101.jpg",
    "img":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bd56ae48-321b-45f3-c0cc-8465686dfc00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 19,
    "title": "Wizard's Apprentice",
    "description": "Wizard's Apprentice",
    "designer": "Noburo",
    "paper": "1 Sheet of 35cm kami",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/DSC_0012.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/c8b38ee8-34d3-4085-812b-044ccc04b100/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "People"
  },
  {
    "id": 20,
    "title": "Angler Fish",
    "description": "Angler Fish",
    "designer": "Bois Wong",
    "paper": "1 Sheet of 25cm blank kami",
    "source": "Taught at WOD 2020, recordings available at OrigamiUSA",
    "img-local": "./images/DSC_0013.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/620c932b-c94e-48cf-0cea-698fa693ca00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 21,
    "title": "Baby Dragon",
    "description": "Baby Dragon",
    "designer": "Daniella Carboni",
    "paper": "1 Sheet of orange japanese foil",
    "source": "License to fold by Nicholas Terry and other contributors",
    "img-local": "./images/DSC_00141.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/e8d974d5-8a1d-4bd6-92ec-3bcffed54300/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 22,
    "title": "Owl For Tomoko",
    "description": "Owl For Tomoko",
    "designer": "Beth Johnson",
    "paper": "1 Sheet of brown kami",
    "source": "Taught at WOD 2020, recordings available at OrigamiUSA",
    "img-local": "./images/DSC_0016.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7ac146e5-8696-4ac3-c7b8-e8d8c5c49200/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Birds"
  },
  {
    "id": 23,
    "title": "Crane with flapping wings",
    "description": "Crane with flapping wings",
    "designer": "Tomoko Fuse",
    "paper": "1 Sheet of 15cm orange kami, 6 sheets of 7.5cm orange kami",
    "source": "Taught at WOD 2020, recordings available at OrigamiUSA",
    "img-local": "./images/DSC_0018.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/bdcd230b-e55e-4804-8f1d-a0aa6b2cad00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Birds"
  },
  {
    "id": 24,
    "title": "Winged Kirin - Head",
    "description": "Definitely my favorite model I have folded! The unryu paper, even when treated, was still so “floppy”, that I had to give it a flying stand with wire.",
    "designer": "Satoshi Kamiya",
    "paper": "MC treated Unryu, 60cm",
    "source": "JOAS 2019 special issue",
    "img-local": "./images/Kirin-Head.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/a9160562-bd3a-4d81-7a96-c25aaa19e700/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 25,
    "title": "Winged Kirin - Full",
    "description": "Definitely my favorite model I have folded! The unryu paper, even when treated, was still so “floppy”, that I had to give it a flying stand with wire.",
    "designer": "Satoshi Kamiya",
    "paper": "MC treated Unryu, 60cm",
    "source": "JOAS 2019 special issue",
    "img-local": "./images/Kirin-Full.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/b8fea0ba-f7a2-4ca4-542e-80fe14e33700/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 26,
    "title": "Bactrian Camel - Fold2",
    "description": "This was my 2nd attempt, this time with Zanders Elephant Hide. A VERY fun model to fold, but has some pretty challenging steps.",
    "designer": "Shuki Kato",
    "paper": "Elephant Hide, 48cm",
    "source": "Origami Nature Study by Shuki Kato",
    "img-local": "./images/Bactrian-Camel.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/6942ebcf-53ff-4a3d-654e-3d9891143f00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 27,
    "title": "Manta Ray",
    "description": "A rather simple model, but a great wet-fold!",
    "designer": "Satoshi Kamiya",
    "paper": "Leather paper (Golden River) 15cm",
    "source": "Works of Satoshi Kamiya 3 by Satoshi Kamiya",
    "img-local": "./images/Mantaray.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/ddc8de65-dd64-4ac4-21fb-1acd72bfd500/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 28,
    "title": "Giant Water Bug ( Kirkaldyia deyrolli )",
    "description": "A stunning insect designed by Kota Imai, who I must say, probably is the best origami insect designer. This model is simple compared to some of his other bugs! (I don’t think I can fold THOSE).",
    "designer": "Kota Imai",
    "paper": "Double tissue 25 cm",
    "source": "Tanteidan 24th convention",
    "img-local": "./images/Giant-Water-Bug.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/af8eef05-a12f-4f94-9d76-ca4f67b1b500/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 29,
    "title": "White Rhinoceros",
    "description": "(pretty ironic that I folded it with black paper lol) A rather cartoonish but COOL model!",
    "designer": "Quentin Trollip",
    "paper": "Elephant Hide 48cm",
    "source": "Origami Sequence by Quentin Trollip",
    "img-local": "./images/Rhino.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/1853aa45-6d27-4c82-28de-d2f193a8a500/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 30,
    "title": "Deinonychus",
    "description": "Being someone fascinated with Dinosaurs, this was a must-fold.",
    "designer": "Satoshi Kamiya",
    "paper": "35 cm Biotope",
    "source": "Works of Satoshi Kamiya 3 by Satoshi Kamiya",
    "img-local": "./images/Deinonychus.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/974481c0-ae13-436e-71d9-2f6e5b61c400/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "Animals"
  },
  {
    "id": 31,
    "title": "Waterbomb tesselation",
    "description": "One of my first SUCCESSFUL tessellations!",
    "designer": "Eric Gjerde",
    "paper": "Tant 15cm",
    "source": "Awe-Inspiring Tessellations by Eric Gjerde",
    "img-local": "./images/Waterbomb.jpg",
    "img": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/public",
    "img-min": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=336",  
    "img-sm": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=640",    
    "img-lg": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=1024", 
    "img-2xl": "https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=1536", 
    "srcset":"https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=336 336w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=640 640w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=1024 1024w, https://imagedelivery.net/KKVXNmUvN9b_4Ws7SZUr5g/7c3f0840-8d02-4631-f0b5-96acef7f8a00/w=1536 1535w",
    "sizes":"(max-width: 640px) 336px, (max-width: 1024px) 640px, 1024px",
    "country": "United States of America",
    "album": "People"
  }
];
  export default origamiData;
  