import React, {PureComponent} from "react";
import { Scrollama, Step } from "react-scrollama";
import Origami from "./Origami";
import origamiData from "../data/origamiData";

class ScrollyTelling extends PureComponent{
  state = {
    index: 0,
    progress: 0,
    direction: 'up',
    opacity: 1,
    imageLoaded: false
  };
  
    onStepEnter = e => {
      const { data, direction } = e;
      this.setState({ index: data, direction: direction });
    };
    
    onStepExit = e => {
      const { data, direction } = e;
    };
    
    onStepProgress = e => {
      const { data, progress, direction} = e;  
      let opacity = 1;

      if(progress >= 0.5){
        opacity = 1 - (progress - 0.5) * 2
      }

      if(this.state.imageLoaded || (direction == 'up') ){
        this.setState({ opacity: opacity})
      }

    };

    onImageLoad = e =>{
      this.setState({ imageLoaded: true})
    }

    render(){
      const { index, steps, progress } = this.state;
      
        return (
          <div className='grid bg-slate-800 space-y-10 pl-10 pr-10 z-40 h-screen justify-items-center lg:flex lg:space-x-10 lg:space-y-0'>
                <img style={{opacity: `${this.state.opacity}`}} 
                  className='h-100 top-5 z-10 w-auto rounded sticky lg:h-[32rem] lg:-translate-y-24 2xl:h-[48rem] 2xl:-translate-y-20 lg:top-1/2 ' 
                  srcset={origamiData[this.state.index].srcset}
                  sizes={origamiData[this.state.index].sizes}
                  src={origamiData[this.state.index]["img-min"]} alt={origamiData[this.state.index].title} 
                  onLoad={this.onImageLoad}/>
                 {/* className='h-64 -translate-y-32 sm:h-96 sm:-translate-y-48 lg:h-[32rem] lg:-translate-y-64 2xl:h-[48rem] 2xl:-translate-y-96 w-auto rounded sticky top-10 lg:top-1/2 z-10' */}
                  <div className='block overflow-y-auto scrollbar-none'>
                    {/* show the description here: scrollable */}
                      <Scrollama 
                        onStepEnter={this.onStepEnter} 
                        onStepExit={this.onStepExit}
                        progress
                        onStepProgress={this.onStepProgress}
                        offset="0.5"
                        >
                          {origamiData.map((origami, index) => (
                              <Step key={index} data={index}>
                                  {/* <Origami origami={origami} /> */}
                                  <div className="flex flex-row h-screen justify-center items-center"> 
                                  <div className="grid">
                                    <article className="text-wrap">
                                      <h3 className='font-bold text-center text-white'>{origami.title}</h3>
                                      <p className='text-center text-slate-400'>{origami.description}</p>
                                    </article>
                                    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-slate-400"></hr>
                                    <table>
                                      <tr className="grid grid-cols-11 text-slate-400">
                                        <td className="text-right col-span-5">Designer</td>
                                        <td className="text-center justify-self-center w-1">:</td>
                                        <td className="text-left  col-span-5">{origami.designer}</td>
                                      </tr>
                                      <tr className="grid grid-cols-11 text-slate-400">
                                        <td className="text-right col-span-5">Paper</td>
                                        <td className="text-center justify-self-center w-1">:</td>
                                        <td className="text-left col-span-5">{origami.paper}</td>
                                      </tr>
                                      <tr className="grid grid-cols-11 text-slate-400">
                                        <td className="text-right col-span-5">Source</td>
                                        <td className="text-center justify-self-center w-1">:</td>
                                        <td className="text-left col-span-5">{origami.source}</td>
                                      </tr>
                                      <tr className="grid grid-cols-11 text-slate-400">
                                        <td className="text-right col-span-5">Album</td>
                                        <td className="text-center justify-self-center w-1">:</td>
                                        <td className="text-left col-span-5">{origami.album}</td>
                                      </tr>
                                    </table>
                                    </div>
                                  </div>  
                              </Step>
                          ))}
                      </Scrollama>
                  </div>
          </div>
        );
      };
}


export default ScrollyTelling;

