import logo from './logo.svg';
// import './App.css';
import './App-output.css';
import React from "react";
import Scrollytelling from "./components/ScrollyTelling";

function App() {
  return (
    <div className='block'>
      {/* <header className='fixed top-0 left-0 right-0 z-50 p-6 bg-lime-600'>
        <h1 className='text-center font-bold'>Origami Gallery</h1>
      </header> */}
        
      <Scrollytelling />
    </div>
  );
}

export default App;
