// src/components/Origami.js
import { memo, React } from "react";

const Origami = memo( function Origami({origami}){
  return (
    <div className="flex flex-row h-screen p-10 justify-center items-center"> 
      <div>
        <div className='font-bold text-center'>{origami.name}</div>
        <div className='text-center'>{origami.description}</div>
      </div>
    </div>  
);
});

export default Origami;
